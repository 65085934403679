<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    color="accent"
    :right="$vuetify.rtl"
    :src="drawerImage ? image : ''"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    border="0px"
    app
    width="260"
  >
    <template
      v-if="drawerImage"
      #img="props"
    >
      <v-img
        :key="image"
        :gradient="gradient"
        v-bind="props"
      />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <default-list :items="listItems" />
    </div>
    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultDrawer',

    components: {
      DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
      DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        './List'
      ),
    },
    data () {
      return {
        listItems: [],
      }
    },
    computed: {
      ...get('user', ['user_data', 'dark', 'gradient', 'image']),
      ...get('app', ['version']),
      ...sync('app', ['drawer', 'items', 'drawerImage', 'mini']),
    },
    created () {
      this.listItems = [
        {
          title: this.$t('drawer.Dashboard'),
          icon: 'mdi-view-dashboard',
          to: '/',
        },
        {
          title: this.$t('drawer.CommonManager'),
          icon: 'mdi-account',

          items: [
            {
              title: this.$t('drawer.CustomerList'),
              icon: 'mdi-face-agent ',
              to: '/customer-list',
            },
            {
              title: this.$t('drawer.JobList'),
              icon: 'mdi-bulletin-board',
              to: '/jobs',
            },
            {
              title: this.$t('drawer.RevenueManagementMenu'),
              icon: 'mdi-account-cash',
              to: '/revenue-management',
            },
            {
              title: this.$t('drawer.VisaManager'),
              icon: 'mdi-card-account-details',
              to: '/parttimer-visa',
            },
            {
              title: this.$t('drawer.CollegesManager'),
              icon: 'mdi-bank',
              to: '/parttimer-colleges',
            },
          ],
        },
        {
          title: this.$t('drawer.ParttimerManager'),
          icon: 'mdi-account',

          items: [
            {
              title: this.$t('drawer.ParttimerList'),
              icon: 'mdi-view-list-outline ',
              to: '/parttimer-list',
            },
            {
              title: this.$t('drawer.ParttimerSignupWorktime'),
              icon: 'mdi-calendar-clock',
              to: '/parttimer-worktime-signup',
            },
            {
              title: this.$t('drawer.ParttimerSignupView'),
              icon: 'mdi-calendar-clock',
              to: '/parttimer-worktime-view',
            },
            {
              title: this.$t('drawer.EvaluationPartTime'),
              icon: 'mdi-account-star-outline ',
              to: '/evaluation-parttime',
            },
            {
              title: this.$t('drawer.IssuanceOfRetirementCertificate'),
              icon: 'mdi-file-document-multiple-outline',
              to: '/document',
            },
          ],
        },
        {
          title: this.$t('drawer.ShiftManagement'),
          icon: 'mdi-clock',

          items: [
            {
              title: this.$t('drawer.ShiftList'),
              icon: 'mdi-account-details',
              to: '/shift-list',
            },
            {
              title: this.$t('drawer.FixedList'),
              icon: 'mdi-view-list-outline',
              to: '/shift-fixed-list',
            },
            {
              title: this.$t('drawer.FixedAttendanceList'),
              icon: 'mdi-view-list ',
              to: '/fixed-attendance-list',
            },
            {
              title: this.$t('drawer.PlanAssign'),
              icon: 'mdi-floor-plan',
              to: '/plan-assign',
            },
            {
              title: this.$t('drawer.FactoryWorkManagement'),
              icon: 'mdi-city',
              to: '/factory-work-assign',
            },
          ],
        },
        {
          title: this.$t('drawer.AttendanceManagement'),
          icon: 'mdi-clock',
          items: [
            {
              title: this.$t('drawer.TimeSheetEmployee'),
              icon: 'mdi-face-agent ',
              to: '/time-sheet-employee',
            },
            {
              title: this.$t('drawer.TimeSheetDepartment'),
              icon: 'mdi-face-agent ',
              to: '/time-sheet-department',
            },
            {
              title: this.$t('drawer.ListTimeSheet'),
              icon: 'mdi-clipboard-text',
              to: '/time-sheet',
            },
            {
              title: this.$t('drawer.Management28h'),
              icon: 'mdi-archive-clock',
              to: '/28h-manage',
            },
          ],
        },
        {
          title: this.$t('drawer.Report'),
          icon: 'mdi-summit',
          items: [

            {
              title: this.$t('drawer.TimeKeepingExport'),
              icon: ' mdi-file-export-outline  ',
              to: '/time-keeping-export',
            },
            {
              title: this.$t('drawer.RevenueManagement'),
              icon: ' mdi-factory  ',
              to: '/revenue-management-factories',
            },
            {
              title: this.$t('drawer.DocumentStatus'),
              icon: 'mdi-account-group',
              to: '/parttimer-document-status',
            },
            {
              title: this.$t('drawer.TimesheetDisplay'),
              icon: 'mdi-chart-timeline ',
              to: '/time-sheet-display',
            },
            {
              title: this.$t('drawer.DashboadFulltime'),
              icon: 'mdi-trending-up',
              to: '/dashboad-fulltime',
            },
          ],
        },
        {
          title: this.$t('drawer.EmployeeManager'),
          icon: 'mdi-account',

          items: [
            {
              title: this.$t('drawer.ListEmployee'),
              icon: 'mdi-view-list-outline ',
              to: '/employee-list',
            },
            {
              title: this.$t('drawer.EvaluationFullTime'),
              icon: 'mdi-account-star',
              to: '/evaluation-fulltime',
            },
          ],
        },
      ]
      this.checkRoles()
    },
    methods: {
      checkRoles () {
        if (localStorage.getItem('user')) {
          const userRole = JSON.parse(localStorage.getItem('user')).detail.type
          const routes = this.$router.options.routes
          routes.forEach(parent => {
            parent.children.forEach(route => {
              this.listItems.forEach(parentItem => {
                if (parentItem.items) {
                  parentItem.items.forEach(item => {
                    if (route.path === item.to) {
                      if (!route.meta.role.includes(userRole)) {
                        parentItem.items.splice(parentItem.items.indexOf(item), 1)
                      }
                    }
                  })
                  if (parentItem.items.length === 0) {
                    this.listItems.splice(this.listItems.indexOf(parentItem), 1)
                  }
                }
                if (parentItem.to) {
                  if (route.path === parentItem.to) {
                    if (!route.meta.role.includes(userRole)) {
                      this.listItems.splice(this.listItems.indexOf(parentItem), 1)
                    }
                  }
                }
              })
            })
          })
        }
      },
    },
  }
</script>

<style lang="sass">

#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
.v-list-item__title
  white-space: normal!important
</style>
